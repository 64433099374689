export function convert(selectors = []){
    selectors.map((selector) => {

        let $form = $(selector);

        $form.find('button[type="submit"]').click((e) => {
            e.preventDefault();
            send($form);
        });

    })
}

let send = ($form) => {
    let o = {
        data: $form.inputs2obj(),
        method: $form.attr('method'),
        url: $form.attr('action'),
        error: (r) => {error(r, $form)},
        success: (r) => {success(r, $form)},
    };


    $.ajax(o);

};

let error = (response, $form) => {
    let errors = response.responseJSON.errors;

    //console.log(response);

    for (let key in errors)
    {
        if (!errors.hasOwnProperty(key)) continue;

        let $feedback = $('<div>', {
            class: 'feedback d-block contrast2',
            html: errors[key]
        });

        $form
            .find('.feedback')
            .remove()
            .end()
            .find('input[name="'+ key +'"]')
            .parent()
            // .find('.feedback').remove()
            // .append($('<div class="flex-br">'))
            .before($feedback);
    }

    M.toast({html: 'Hiba!', classes: 'bg-contrast2'});
};

let success = (response, $form) => {
    $form.find('.feedback').remove();
    M.toast({html: response, classes: 'bg-contrast'});
};