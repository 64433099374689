let tweens = {};
let dur_a = .8;
let dur_b = 1.5;
let dur_stagger = .2;
let distance = () => {return 300};
let ease = {
    back: Back. easeOut.config( 1),
    back2: Back. easeOut.config( 2),
    back3: Back. easeInOut.config( 1),
    elastic:  Elastic. easeOut.config( 1, 0.3),
    elastic2:  Elastic. easeOut.config( 1, 0.5),
    expo:  Expo. easeOut,
    power4:  Power4. easeOut};

tweens.index = () => {
    let tmp = {};

    tmp.rolunk = new TimelineMax()
        .fromTo("#rolunk-txt-wrap > .col-lg-7", dur_a, {y:'6rem'}, {y: '-6rem'})
        .to(".rolunk-img", dur_a, {'margin-top':'4rem'}, 0);


    tmp.hol =  new TimelineMax()
        .staggerFrom("#hol .stagger", 1, {y:() => {return 100}, autoAlpha: 0, ease: Power4.easeOut}, .1);

    tmp.szolgaltatas = new TimelineMax()
        .from(".szolgaltatasok-txt", 1.2, {y:() => {return -distance()}, autoAlpha: 0, ease: ease.power4})
        .staggerFrom("#szolgaltatasok .list-01 > li", 1, {y:() => {return distance()}, autoAlpha: 0, ease: ease.power4, }, .5, 0);

    tmp.termekek = new TimelineMax()
        .staggerFrom(".termekek-sor .switcheroo, .termek", 1.4, {x:() => {return -distance()}, autoAlpha: 0, ease: ease.power4}, .3);

    return tmp;

};

tweens.szolgaltatasok = () => {
    let tmp = {};

    //should be the same as on index
    tmp.szolgaltatas = new TimelineMax()
        .from(".szolgaltatasok-txt", dur_a, {y:() => {return -distance()}, autoAlpha: 0, ease: ease.power4})
        .staggerFrom("#szolgaltatasok .list-01 > li", 1, {y:() => {return distance()}, autoAlpha: 0, ease: ease.power4, }, .5, 0);

    return tmp;
};


tweens.szolgaltatas_hoszivattyu = () => {
    //could aboud declaring tmp by returning an object right away
    let tmp = {};

    tmp.section_1 = new TimelineMax()
        .staggerFrom(".section-1 .stagger", 1, {y:() => {return 50}, autoAlpha: 0, ease: ease.power4, }, .2, 0);

    tmp.section_2 = new TimelineMax()
        .staggerFrom(".section-2 .stagger", 1, {y:() => {return 50}, autoAlpha: 0, ease: ease.power4, }, .2, 0);

    tmp.section_3 = new TimelineMax()
        .staggerFrom(".section-3 .stagger", 1, {y:() => {return 50}, autoAlpha: 0, ease: ease.power4, }, .2, 0);

    tmp.section_4 = new TimelineMax()
        .staggerFrom(".section-4 .stagger, .section-4 .list-01 > li", 1.2, {y:() => {return 150}, autoAlpha: 0, ease: ease.power4, }, .4, 0);

    tmp.section_5 = new TimelineMax()
        .staggerFrom(".section-5 .stagger", 1, {y:() => {return 50}, autoAlpha: 0, ease: ease.power4, }, .2, 0);

    return tmp;

};

tweens.szolgaltatas_klima = () => {
    let tmp = {};

    tmp.section_1 = new TimelineMax()
        .staggerFrom(".section-1 .stagger", 1, {y:() => {return 50}, autoAlpha: 0, ease: ease.power4, }, .2, 0);

    tmp.section_2 = new TimelineMax()
        .fromTo(".parallax > img", 1, {top:'-70%', }, {top:'-10%', ease: Linear.easeNone});

    tmp.section_3 = new TimelineMax()
        .staggerFrom(".section-2 .stagger", 1, {y:() => {return 50}, autoAlpha: 0, ease: ease.power4, }, .2, 0);

    tmp.section_4 = new TimelineMax()
        .staggerFrom(".section-3 .stagger, .section-3 .list-01 > li", 1.2, {x:() => {return 150}, autoAlpha: 0, ease: ease.power4, }, .4, 0);

    tmp.termekek = new TimelineMax()
        .staggerFrom(".termekek-sor .switcheroo, .termek", 1.4, {x:() => {return -distance()}, autoAlpha: 0, ease: ease.power4}, .3);

    return tmp;
};

tweens.szolgaltatas_htarifa = () => {

    return{
        section_1: new TimelineMax()
            .staggerFrom(".section-1 .stagger", 1, {y:() => {return 50}, autoAlpha: 0, ease: ease.power4}, .2, 0),
        section_2: new TimelineMax()
            .staggerFrom(".section-2 .stagger", 1, {y:() => {return 50}, autoAlpha: 0, ease: ease.power4}, .2, 0),
        section_3:new TimelineMax()
            .staggerFrom(".section-3 .stagger", 1, {y:() => {return 50}, autoAlpha: 0, ease: ease.power4}, .2, 0),
        section_4: new TimelineMax()
            .staggerFrom(".section-4 .stagger", 1, {y:() => {return 50}, autoAlpha: 0, ease: ease.power4}, .2, 0)};

};

tweens.szolgaltatas_legtechnika = () => {
    return{
        section_1: new TimelineMax()
            .staggerFrom(".section-1 .stagger", 1, {y:() => {return 50}, autoAlpha: 0, ease: ease.power4, }, .2, 0),
        section_2: new TimelineMax()
            .fromTo(".parallax > img", 1, {top:'-70%', }, {top:'-10%', ease: Linear.easeNone}),
        section_3: new TimelineMax()
            .staggerFrom(".section-3 .stagger", 1, {y:() => {return 50}, autoAlpha: 0, ease: ease.power4, }, .2, 0)};

};


export function get(){
    return (tweens[a.url]) ?  tweens[a.url]() : null;
}
