
let $nav = a.$nav,
    $logo = $('#logo-link'),
    $links = $('#nav-ul'),
    $links_wrap = $('#nav-ul-wrap'),

    $burger = $('nav .burger-a'),
    bar1 = $burger.find('span:first-of-type'),
    bar2 = $burger.find('span:nth-of-type(2)'),
    bar3 = $burger.find('span:last-of-type'),

    was_animated = false

;

$burger.click(toggle);
a.$win.on('window_shrank', onWinShrank);
a.$win.on('window_stretched', onWinStretch);


function toggle() {
    let tl;

    was_animated = true;

    if (isExpanded()) {
        tl = collapse();
    }else{
        tl = expand();
    }

    return tl;

}

function isExpanded() {
    return $nav.hasClass('expanded');
}

function collapse() {
    toggleBurger();
    $nav.removeClass('expanded');

    return new TimelineMax()
        .from($links_wrap, {
            y: '0%',
            display: 'block',
            ease:   Expo. easeOut

        })
        .to($links_wrap, 1.4,
            {
                y: '-100%',
                display: 'none',
                ease:   Expo. easeOut
            }, 0);
}

function expand() {
    toggleBurger();

    $nav.addClass('expanded');

    return new TimelineMax()
        .from($links_wrap,  {
            y: '-100%',
            display: 'block',
            ease:        Expo. easeOut

        })
        .to($links_wrap, 1.4,
            {
                y: '0%',
                display: 'block',
                ease:   Expo. easeOut
            }, 0);
}

function onWinStretch() {
    resetLinks();
    a.$nav.addClass('expanded');
    toggleBurger();
}

function onWinShrank() {
    resetLinks();
    a.$nav.removeClass('expanded');
}

function toggleBurger() {


    // let ease = Elastic. easeInOut.config( 2.5, 1);
    let ease =  Back. easeOut.config( 2);


    if (isExpanded()) {
        new TimelineMax()
            .to(bar1, .4,
                {
                    rotationZ: 0,
                    top: '30%',
                    // bottom: null,
                    // bottom: 'auto',
                    ease: ease
                })
            .to(bar2, .4,
                {
                    autoAlpha: 1,
                    // ease: Cubic.easeOut
                }, 0)
            .to(bar3, .4,
                {
                    rotationZ: 0,
                    bottom: '30%',
                    // top: null,
                    // top: 'auto',
                    ease: ease
                }, 0);
    }else{
        new TimelineMax()
            .to(bar1, .4,
                {
                    rotationZ: 45,
                    top: '0%',
                    bottom: '0%',
                    ease: ease
                })
            .to(bar2, .4,
                {
                    autoAlpha: 0,
                    // ease: Cubic.easeOut
                }, 0)
            .to(bar3, .4,
                {
                    rotationZ: -45,
                    top: '0%',
                    bottom: '0%',
                    ease: ease
                }, 0);
    }

}

function resetLinks() {
    if (was_animated){
        TweenMax.set($links_wrap, {display: null, transform: null});
    }
}



// class Nav
// {
//     constructor()
//     {
//         this.$nav = $('nav');
//
//
//
//         this.toggle = this.toggle.bind(this);
//         this.expand = this.expand.bind(this);
//         this.collapse = this.collapse.bind(this);
//         this.animateBurger = this.animateBurger.bind(this);
//         this.windowShrank = this.windowShrank.bind(this);
//         this.windowStretched = this.windowStretched.bind(this);
//         this.resetLinks = this.resetLinks.bind(this);
//
//
//         // a.$win.resize(this.windowResize);
//
//
//     }
//
//
//
//     expand(){
//
//
//     }
//     animateBurger(){
//     }
//
//     windowStretched(){
//         //bug: when animation is still running while stretching the window, gsap.set() is overwritten with .to()
//         //  rendering the links invisible
//
//     }
//     windowShrank(){
//
//
//     }
//     resetLinks(){
//
//     }
// }
//
//
// export {Nav};
