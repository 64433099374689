//wont find util.js.map modal.js.map
export function js(scripts = [], cdn = false)
{
    let promise = {};

    scripts.map(function (val, i)
    {
        val = cdn ? val : asset(val);
        
        if (i === 0){
            promise = $.getScript(val);
        }else{
            promise.then(function ()
            {
                $.getScript(val);
            }, function (error)
            {
                console.log('error occoured when loading script', error);
            })
        }
    })
}

export function css(sheets, cdn=false)
{
    setTimeout(() => {
        sheets.map(sheet  =>
        {
            let path = cdn ?  sheet : asset(sheet);
            $('<link type="text/css" rel="stylesheet" href="' + path + '">').appendTo('head');
        })
    }, a.ASYNC_DELAY);
}

export function img()
{
    let imgs = $('img[data-src], .lazybg');

    imgs.map(function (i) {
        let src = this.dataset.src;
        let dummy = $('<img />', {src: src});

        dummy.on('load', function () {
            // alert('k');

            if (imgs[i].tagName === 'IMG'){
                imgs[i].src = src;

            }else{
                imgs.eq(i).css({backgroundImage: 'url(' + src + ')'});
            }

        });
    });

}

function asset(path) {
    return window.location.protocol +"//" + window.location.hostname + "/" + path;
}