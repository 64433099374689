let $modal = $('#modal-termek');


$modal.on('show.bs.modal', fill_termek_table);


function fill_termek_table(e) {
    let data = fetch_termek_data(e.relatedTarget);
    let sections = {};
    let termek_map = {
        price: 'ár (HUF)',
        zajszint: 'zajszint (DB)',
        energiaosztaly: 'energiaosztály',
        telj_hut: 'hűtési teljesítmény (KW)',
        telj_fut: 'fűtési teljesítmény (KW)',
        extrak: 'extrák',
        gari: 'garancia (év)',
    };

    sections.table = $modal.find('.termek-table-modal').empty();
    sections.header = $modal.find('.modal-header > h3').empty();
    sections.kepek = $modal.find('.termek-kepek').empty();

    for (let key in termek_map) {
        if (!data.hasOwnProperty(key)) continue;

        $('<tr>').append(
            $('<td>').html(termek_map[key]),
            $('<td>').html(data[key]))
            .appendTo(sections.table);
    }

    $('<img>', {src: data.img_url}).appendTo(sections.kepek);
    sections.header.html(data['termeknev']);


}
function fetch_termek_data(target){
    let data = $(target).parents('.termek').data('record');
    data = $('<textarea />').html(data).text();
    data = JSON.parse(data);

    return data;
}