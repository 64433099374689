$( function() {

    var $container = $('.grid').masonry({
        itemSelector: '.grid-item',
        // set itemSelector so .grid-sizer is not used in layout
        columnWidth: '.grid-item',
        // percentPosition: true,
        transitionDuration: '1.4s',
        // stagger: 500,
        gutter:20,
        hiddenStyle: {
            transform: 'translateY(100px)',
            opacity: 0
        },
        visibleStyle: {
            transform: 'translateY(0px)',
            opacity: 1
        }
    });


    var $items = $('.hide > .grid-item');
    $container.masonryImagesReveal( $items );


});

$.fn.masonryImagesReveal = function( $items ) {
    var msnry = this.data('masonry');
    var itemSelector = msnry.options.itemSelector;
    // hide by default
    $items.hide();
    // append to container
    this.append( $items );
    $items.imagesLoaded().progress( function( imgLoad, image ) {
        // get item
        // image is imagesLoaded class, not <img>, <img> is image.img
        var $item = $( image.img ).parents( itemSelector );
        // un-hide item
        $item.show();
        // masonry does its thing
        msnry.appended( $item );

       msnry.$element.masonry();

    });

    return this;
};

function randomInt( min, max ) {
    return Math.floor( Math.random() * max + min );
}


