require('./boot').strap({
    run_page_loader:    true,
    run_smagic:         true,
    animations:         true,
    ASYNC_DELAY:        100,
});

let routes = {};

$('header .btn.shape-arrow-down').click((e) => {
    $("html, body").animate({ scrollTop: a.$header.height() }, 1000);
});

$('#btn-scrolltop').click((e) => {
    $("html, body").animate({ scrollTop: 0 }, 400);
});



routes.admin = () =>
{
    $('.table-termekek tr').click(function(e) {
        window.location.replace("/termek/"+ this.dataset.id+"/edit" );
    });
};

routes.termekek = () =>
{
    require('./components/masonry-imgloaded');

    require('./components/async-asset-loader').js(['js/vendor/bootstrap/util.js','js/vendor/bootstrap/modal.js'])
    require('./controllers/termekek_modal');


};

routes.index = () =>
{
    require('./components/async-asset-loader').js(['js/vendor/bootstrap/util.js','js/vendor/bootstrap/modal.js'])
    require('./controllers/termekek_modal');

};

routes.szolgaltatas_klima = () =>
{
    require('./components/async-asset-loader').js(['js/vendor/bootstrap/util.js','js/vendor/bootstrap/modal.js'])
    require('./controllers/termekek_modal');

};
routes.kapcsolat = () =>
{

};

routes.regexp = {};

routes.regexp['(_edit|_create)$'] = () => {

    $('.img-already-uploaded').click(function (e)
    {
        let imgs = $('.img-already-uploaded');
        let $this = $(this);

        if (!imgs.filter('.active').is($this)){
            imgs.removeClass('active');
        }

        $this.toggleClass('active');

        if ($this.hasClass('active')) {
            $('#chosen-img').html(this.src.filename());
            $('#ip-chosen_img').val(this.src.filename());
        }else{
            $('#chosen-img').html('');
            $('#ip-chosen_img').val('');
        }
    });
};




routes[a.url] && routes[a.url]();

if (!routes.regexp.isempty()) {
    for (let key in routes.regexp)
    {
        if (!routes.regexp.hasOwnProperty(key)) continue;
        if (a.url.match(key)) {
            routes.regexp[key]();
        }

    }
}

