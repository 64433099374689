let ease = {
    back: 'back.out(1.7)',
    back2: Back. easeOut.config( 2),
    back3: Back. easeInOut.config( 1),
    elastic:  Elastic. easeOut.config( 1, 0.3),
    elastic2:  Elastic. easeOut.config( 1, 0.5),
    expo:  Expo. easeOut,
    power4: Power4.out,
    power4i: Power4.in,
    power4io: Power4.inOut,
};
let dur_a = .6;
let dur_b = 1.5;
let dur_stagger = .2;



let tweens = {};
let common_tween_args = {autoAlpha:0, ease: Power3.out};

tweens.test = () => {
    return new TimelineMax()
        .to($('.spinner'), .5, {scale: 3, autoAlpha: 0,  ease: Power4. easeOut})
        .to($('#spinner-wrap'), 1.2, {y: '-100%',  ease: Power4. easeIn}, 0)
        .staggerFrom('.stagger-header-a', dur_a, {y:() => {return distance()}, ...common_tween_args}, dur_stagger)
        .staggerFrom('.stagger-header-b', dur_a, {y:() => {return distance()}, ...common_tween_args}, .4, '-=.4')
        .from($('header .contact'), dur_a, {x:() => {return distance()}, ...common_tween_args}, '-=1.2')
        .eventCallback('onComplete',() => {a.$win.off('scroll', scrolltop)})
        .eventCallback('onStart', () => {
            scrolltop();
            a.$win.scroll(scrolltop)})
        // .from('header .align-items-end > div', dur_b, {y:() => {return distance()}, ...common_tween_args, ...{ease: ease.expo}}, '-=.2')
        ;

};

tweens.spinner_to_header = () => {
    let elems = $('#logo-link, #nav-ul-wrap, #nav-sub, .header-content');

    return new TimelineMax()
        .to($('.spinner'), .5, {scale: 3, autoAlpha: 0,  ease: Power4. easeOut, delay: .2})
        .to($('#spinner-wrap'), 1.2, {y: '-100%',  ease: Power4. easeIn}, '-=.1')
        .from('header .contact', .8, {y:() => {return distance()}, autoAlpha:0, ease: Power4. easeOut}, '-=.2')
        .from(elems, .8, {y:() => {return distance()}, autoAlpha:0, ease: Power4. easeOut}, '-=.1')
        .staggerFrom(elems, .8, {y:() => {return distance()}, autoAlpha:0, ease: Power4. easeOut}, .1, '-=.2')
        // .from('.rolunk-img', dur_b, {'y':300, ease: ease.power4}, '-=.7')
        .eventCallback('onComplete',() => {a.$win.off('scroll', scrolltop)})
        .eventCallback('onStart', () => {
            scrolltop();
            a.$win.scroll(scrolltop)});

};

function distance() {
    return '120px';
}
function distance2() {
    return [300, 470].rnd();
}



export function get(){
    // return tweens.spinner_to_header();
    return tweens;
}
// export {tweens};
