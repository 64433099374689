export function strap(o)
{
    require('./components/_ext/array');
    require('./components/_ext/obj');
    require('./components/_ext/string');
    require('./components/_ext/jquery');

    o = {...{
        run_page_loader:    true,
        run_smagic:         true,
        animations:         true
        }, ...o};

    a = {...a, ...o};

    let url = window.location.pathname;
        url = (url === '/') ? 'index' : url.substr(1);

    // a.url   = url.replace('/', '_');
    // a.url   = url.replaceAll('/', '_');
    // a.url   = url.replaceAll('/', '_');
    a.url = url.split("/").join("_");
    a.$win  = $(window);
    a.$win.trigger('doc_ready');
    a.$body = $('body');
    a.$nav  = $('nav');
    a.$header = $('header');
    a.csrf = $('meta[name="csrf-token"]').attr('content');
    a.is_sm = () => {
        return a.$win.outerWidth() <= 991.98;
    };

    // $.ajaxSetup({cache: true });

    a.is_sm() && mobile_vh_jitter_fix();

    require('./components/async-asset-loader').img();
    require('./components/async-asset-loader').css(['css/mat-inputs.css']);
    require('./components/async-asset-loader').js(
        [
            'js/vendor/materialize/materialize.min.js',
            'js/vendor/materialize/cash.js',
            'js/vendor/materialize/forms.js'
        ]);

    require('./components/window_tracker');
    require('./controllers/nav');

    require('./components/ajax').convert(['#form-feliratkozas']);


    a.tweens = require('./components/tweens').get();

    if(o.animations){
        require('./components/page-loader');
        require('./components/snowfall');

        if (o.run_smagic && !a.is_sm()) {
            let tweens = require('./components/smagic/tweens').get();
            let scenes = require('./components/smagic/scenes').get();

            (tweens !== null) && assembleScenes(tweens, scenes);
        }
    }


    function mobile_vh_jitter_fix() {
        // a.$header.height(a.$header.height());
        // a.$header.css({
            // height: 'auto',
            // 'min-height': a.$header.height()});
        // a.$header.css({height: a.$header.height(), 'min-height': 'auto'});
    }

    function assembleScenes(tweens, scenes) {
        let controller = new ScrollMagic.Controller();

        for (let key in scenes) {
            if (!scenes.hasOwnProperty(key)) continue;

            scenes[key].setTween(tweens[key]).addTo(controller);
        }
    }


}
