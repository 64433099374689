let scenes = {};

scenes.index = () => {
    let tmp = {};

    tmp.rolunk = new ScrollMagic.Scene({
        reverse: true,
        triggerElement: "#rolunk",
        duration: '200%',
        triggerHook: 1
    });

    // tmp.rolunk.addIndicators({name: "rolunk"});

    tmp.hol = new ScrollMagic.Scene({
        reverse: false,
        triggerElement: "#hol",
        duration: '0',
        offset: 300
    });

    tmp.szolgaltatas = new ScrollMagic.Scene({
        reverse: false,
        triggerElement: "#szolgaltatasok",
        duration: '0%',
        triggerHook: .4,
        offset: -100
    });

    tmp.termekek = new ScrollMagic.Scene({
        reverse: false,
        triggerElement: ".termekek-sor",
        duration: '0%',
        // offset: -100
    });

    // tmp.termekek.addIndicators({name: 'termekek'})

    return tmp;

};


scenes.szolgaltatasok = () => {
    let tmp = {};

    tmp.szolgaltatas = new ScrollMagic.Scene({
        reverse: false,
        triggerElement: "#szolgaltatasok",
        duration: '0%',
        triggerHook: .4,
        offset: -100
    });

    return tmp;
};


scenes.szolgaltatas_hoszivattyu = () => {
    let tmp = {};

    tmp.section_1 = new ScrollMagic.Scene({
        reverse: false,
        triggerElement: ".section-1",
        duration: '0%',
        triggerHook: .4,
        offset: -100
    });

    tmp.section_2 = new ScrollMagic.Scene({
        reverse: false,
        triggerElement: ".section-2",
        duration: '0%',
        triggerHook: .4,
        offset: -100
    });
    tmp.section_3 = new ScrollMagic.Scene({
        reverse: false,
        triggerElement: ".section-3",
        duration: '0%',
        triggerHook: .4,
        offset: -100
    });
    tmp.section_4 = new ScrollMagic.Scene({
        reverse: false,
        triggerElement: ".section-4",
        duration: '0%',
        triggerHook: .4,
        offset: -100
    });
    tmp.section_5 = new ScrollMagic.Scene({
        reverse: false,
        triggerElement: ".section-5",
        duration: '0%',
        triggerHook: .4,
        offset: -100
    });

    return tmp;
};

scenes.szolgaltatas_klima = () => {
    let tmp = {};

    tmp.section_1 = new ScrollMagic.Scene({
        reverse: false,
        triggerElement: ".section-1",
        duration: '0%',
        triggerHook: .4,
        offset: -100
    });

    tmp.section_2 = new ScrollMagic.Scene({
        reverse: true,
        triggerElement: ".parallax",
        duration: '160%',
        triggerHook: 1,
        offset: 0
    });



    tmp.section_4 = new ScrollMagic.Scene({
        reverse: false,
        triggerElement: ".section-3",
        duration: '0%',
        triggerHook: .4,
        offset: -100
    });

    tmp.section_3 = new ScrollMagic.Scene({
        reverse: false,
        triggerElement: ".section-2",
        duration: '0%',
        triggerHook: .4,
        offset: -100
    })
    ;


    tmp.termekek = new ScrollMagic.Scene({
        reverse: false,
        triggerElement: ".section-4",
        duration: '0%',
        triggerHook: .7,
        offset: -100
    });

    return tmp;
};

scenes.szolgaltatas_htarifa = () => {

    return {

        section_1: new ScrollMagic.Scene({
            reverse: false,
            triggerElement: ".section-1",
            duration: '0%',
            triggerHook: .4,
            offset: -100
        }),

        section_2: new ScrollMagic.Scene({
            reverse: false,
            triggerElement: ".section-2",
            duration: '0%',
            triggerHook: .4,
            offset: -100
        }),
        section_3: new ScrollMagic.Scene({
            reverse: false,
            triggerElement: ".section-3",
            duration: '0%',
            triggerHook: .4,
            offset: -100
        }),
        section_4: new ScrollMagic.Scene({
            reverse: false,
            triggerElement: ".section-4",
            duration: '0%',
            triggerHook: .4,
            offset: -100
        })
    }
};

scenes.szolgaltatas_legtechnika = () => {
    let tmp = {};

    tmp.section_1 = new ScrollMagic.Scene({
        reverse: false,
        triggerElement: ".section-1",
        duration: '0%',
        triggerHook: .4,
        offset: -100
    });

    tmp.section_2 = new ScrollMagic.Scene({
        reverse: true,
        triggerElement: ".parallax",
        duration: '160%',
        triggerHook: 1,
        offset: 0
    });
    tmp.section_3 = new ScrollMagic.Scene({
        reverse: false,
        triggerElement: ".section-3",
        duration: '0%',
        triggerHook: .4,
        offset: -100
    });

    return tmp;
};

export function get() {
    return (scenes[a.url]) ?  scenes[a.url]() : null;
}