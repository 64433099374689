

//resize
(function(){
    if (a.is_sm()){
        a.$body.addClass('sm');
        a.$nav.removeClass('expanded')
    }

    a.$win.resize(function(){
        if (a.is_sm() && !a.$body.hasClass('sm'))
        {
            a.$body.addClass('sm');
            a.$win.trigger('window_shrank');
        }
        else if (!a.is_sm() && a.$body.hasClass('sm'))
        {
            a.$body.removeClass('sm');
            a.$win.trigger('window_stretched');
        }
    });
})();


(function(){
    let pos = a.$win.scrollTop();

    let DELAY_MAX = 1000,
        trigger = a.$header.height(),
        delay = 0;

    if (pos >= trigger){
        a.$body.addClass('scrolled');
    }

        // last_result = false;

    a.$win.scroll(function(){
        pos = a.$win.scrollTop();

        if (pos >= trigger && !a.$body.hasClass('scrolled')){
            a.$body.addClass('scrolled');
        }else if (pos < trigger && a.$body.hasClass('scrolled')){
            a.$body.removeClass('scrolled');

        }

    });

})();