let tween = {};

if (a.run_page_loader){
    tween = a.tweens.test();
    // tween = a.tweens.spinner_to_header();
    // a.tweens.spinner_to_header = tween;
    // let tween =     require('./tweens').spinner_to_header();

    let spinner = $('#spinner-wrap');

    prepare();

    setTimeout(transition, 400);

}else{

    $('#spinner-wrap').remove();

}


function prepare() {
    // TweenLite.set(elems, {opacity: 0});
}
function transition() {
    // let spinner_tween = TweenMax.to(spinner, 1, {x: '100%', ease: Power4. easeIn});

    // tween.add(spinner_tween, '0').play();


    //todo:
    //scroll to top
    //disable scroll
    //in callback remove spinner
    //wait for images
    tween.play();
}


// $(window).keypress(function (e) {
//     if (e.key === "a") {
//         tween.seek(0);
//         tween.play();
//     }
// });
