Object.defineProperty(Object.prototype, "isempty", {
    enumerable: false,
    value: function () {
        for(let key in this) {
            if(this.hasOwnProperty(key))
                return false;
        }
        return true;

    }
});
Object.defineProperty(Object.prototype, "haskey", {

    enumerable: false,
    value: function (key) {
        if (key === '*') {
            return !this.isempty();
        }
        return (typeof this[key] !== 'undefined' && this[key] !== '');
    }
});

// Object.prototype.isEmpty2 = function() {
//     for(var key in this) {
//         if(this.hasOwnProperty(key))
//             return false;
//     }
//     return true;
// }