$.fn.ezajax = function (success){
    //todo: speed up laravel ajax
    let tag = this[0].tagName;

    if (tag !== 'BUTTON' && tag !== 'FORM'){
        console.log('trying to ajax', this, 'shouldnt');
        return '';
    }

    // let $form = (tag === 'FORM') ? this : this.parents('form');
    // let inputs = $form.inputs2obj();
    //     inputs._token = $('meta[name="csrf-token"]').attr('content');
    //
    // let o ={
    //     // url:  $form[0].action,
    //     url:  '/aportfolio',
    //     method: $form[0].method,
    //     data: inputs,
    //     statusCode: {
    //         404: function(r){
    //             alert(404);
    //         }
    //     },
    //     success: function(r){
    //         console.log('asd');
    //         alert(r);
    //     },
    //     fail: function(r){
    //         console.log('asd');
    //         alert('no');
    //     },
    //     always: function(r){
    //         console.log(r);
    //         console.log('always')
    //     }
    // };

    let o = {};

    o.url = this[0].action;
    o.method = this[0].method;
    o.success = success;
    o.fail = function(r){
        alert('fail');
        console.log(r);
    };
    o.statusCode = {
        404: function(r){
            alert(404);
            console.log(r);
        }
    };


    // console.log(o);

    $.ajax(o);

    // console.log(o);

    // $.ajax(o).done(function(){
    //     console.log('why');
    // });

};

$.fn.getScripts = function(arr, path) {
    var _arr = $.map(arr, function(scr) {
        return $.getScript( (path||"") + scr );
    });

    _arr.push($.Deferred(function( deferred ){
        $( deferred.resolve );
    }));

    return $.when.apply($, _arr);
};

$.fn.inputs2obj = function ()
{
    let o = {};
    let a = this.serializeArray();

    $.each(a, function () {
        if (o[this.name] !== undefined) {

            if (!o[this.name].push) {
                o[this.name] = [o[this.name]];
            }
            o[this.name].push(this.value || '');
        } else {
            o[this.name] = this.value || '';
        }
    });
    return o;
};