Array.prototype.is_emtpy = function(){
	if (this === undefined || this.length == 0) {
	    return true;
	}else {
		return false;
	}
}

Array.prototype.sum = function () {
    var total=0;
    for (var i = 0; i < this.length; i++) {
        total+=this[i];
    }
    return total;
};
Array.prototype.rnd = function () {
    return Math.floor(Math.random() * (this[1] - this[0] + 1) + this[0]);
};
Array.prototype.last = function () {
    return this[this.length - 1];
};
Array.prototype.has = function (item) {
    return this.indexOf(item) > -1;
};
Array.prototype.rmv = function (val) {
    this.has(val) && this.splice(index, 1);
};