let $container = $('.snow-container');
let snow_count = window.a.is_sm() ? 5 : 25;

animateSnow(snow_count, $container, $container.width(), $container.height() );

function animateSnow(total, container, w, h)
{
    var R  = function (min, max)
        {
            return min + Math.random() * (max - min)
        },
        tl = new TimelineLite(),
        i, Div;
    TweenLite.set(container, {perspective: 500});
    for (i = 0; i < total; i++)
    {
        Div = document.createElement('div');
        TweenLite.set(Div, {attr: {class: 'snowflake'}, x: R(0, w), y: R(-200, -150), z: R(-200, 200), 'z-index': -1});

        container.append(Div);

        tl.to(Div, R(6, 15), {y: h + 100, ease: Linear.easeNone, repeat: -1}, 0)
            .to(Div, R(4, 8), {x: '+=100', rotationZ: R(0, 180), repeat: -1, yoyo: true, ease: Sine.easeInOut}, 0)
            .to(Div, R(2, 8), {
                rotationX: R(0, 360),
                rotationY: R(0, 360),
                repeat: -1,
                yoyo: true,
                ease: Sine.easeInOut
            }, 0);
    }
    return tl;
}
